import React, { useEffect } from "react"
import { notification } from "antd"
import iosTab from "../../../static/pwa/ios_tab.png"

const PWA = () => {
  useEffect(() => {
    if (window !== undefined) {
      const messageContent = (
        <div>
          <span>
            Install this webapp on your home screen by Tab&nbsp;&nbsp;
          </span>
          <img src={iosTab} alt="" />
          <span>
            &nbsp;&nbsp;then 'Add to Home Screen' หรือ 'เพิ่มลงในหน้าจอโฮม'
          </span>
        </div>
      )

      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
      }
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone
      const isSafari = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /safari/.test(userAgent)
      }

      const isPWAInstall = localStorage.getItem("isPWAInstall")
      if (isIos() && !isInStandaloneMode() && isSafari() && !isPWAInstall) {
        notification["info"]({
          message: "Progressive Web Apps",
          description: messageContent,
          duration: 0,
          placement: "bottomRight",
          className: "ios_notification_wrapper",
        })
        localStorage.setItem("isPWAInstall", "yes")
      }
    }
  })

  return <div />
}

export default PWA
