import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SeoComp = ({
  lang,
  title,
  description,
  banner,
  pathname,
  article,
  author,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        buildTime,
        siteMetadata: {
          siteUrl,
          defaultTitle,
          defaultDescription,
          defaultBanner,
          titleAlt,
          shortName,
          defaultAuthor,
          siteLanguage,
          pathPrefix,
          logo,
          twitter,
        },
      },
    }) => {
      const seo = {
        lang: lang || siteLanguage,
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: banner || defaultBanner,
        url: pathname || siteUrl,
        author: author || defaultAuthor,
      }
      const realPrefix = pathPrefix === "/" ? "" : pathPrefix
      let schemaOrgJSONLD = [
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "@id": siteUrl,
          url: siteUrl,
          name: defaultTitle,
          alternateName: titleAlt || "",
        },
      ]
      if (article) {
        schemaOrgJSONLD = [
          {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "@id": siteUrl,
            url: seo.url,
            name: title,
            alternateName: titleAlt || "",
            headline: title,
            image: {
              "@type": "ImageObject",
              url: seo.image,
            },
            description: seo.description,
            datePublished: buildTime,
            dateModified: buildTime,
            author: {
              "@type": "Person",
              name: seo.author,
            },
            publisher: {
              "@type": "Organization",
              name: seo.author,
              logo: {
                "@type": "ImageObject",
                url: siteUrl + realPrefix + logo,
              },
            },
            isPartOf: siteUrl,
            mainEntityOfPage: {
              "@type": "WebSite",
              "@id": siteUrl,
            },
          },
        ]
      }
      return (
        <>
          <Helmet title={seo.title}>
            <html lang={seo.lang} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="apple-mobile-web-app-title" content={shortName} />
            <meta name="application-name" content={shortName} />
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>

            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content={article ? "article" : null} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitter} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
          </Helmet>
        </>
      )
    }}
  />
)

export default SeoComp

SeoComp.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  author: PropTypes.string,
}

SeoComp.defaultProps = {
  lang: null,
  title: null,
  description: null,
  banner: null,
  pathname: null,
  article: false,
  author: null,
}

const query = graphql`
  query SeoCompQuery {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle
        defaultDescription
        defaultBanner
        titleAlt
        shortName
        defaultAuthor
        siteLanguage
        pathPrefix
        logo
        twitter
      }
    }
  }
`
