import React from "react"
import ThemeContext from "../context/ThemeContext"
import { Link } from "gatsby"
import Seo from "../components/Frontend/Seo"
import Pwa from "../components/Frontend/Pwa"
import styled from "styled-components"
import logo from "../../static/logo/logo-pea.svg"
import "antd/dist/antd.less"

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #fff;
  .logoWrapper {
    position: relative;
    top: auto;
    left: auto;
    margin: 30px;
    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    span {
      font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
      font-weight: 500;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      padding-left: 10px;
    }
  }
  .left,
  .right {
    width: 100%;
    height: 50vh;
    min-height: 350px;
  }
  .left {
    max-width: 432px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    .contentWrapper {
      margin-top: 30px;
    }
  }
  .right {
    background-image: url("/images/half-bg.jpg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    background-color: #d9d9d9;
    min-height: 512px;
  }

  @media only screen and (min-width: 576px) {
    position: relative;
    .logoWrapper {
      position: absolute;
      top: 30px;
      left: 35px;
      margin: 0;
      span {
        font-size: 24px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .left {
      width: 45%;
      height: 100vh;
    }
    .right {
      width: 55%;
      height: 100vh;
    }
  }
`

const AuthLayout = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {() => {
        return (
          <>
            <Seo title={`Authentication`} description={``} />
            <PageContainer>
              <div className="logoWrapper">
                <Link to="/">
                  <img alt="Solar e-Doc" src={logo} width="132" height="48" />
                  {/* <span>Solar e-Doc</span> */}
                </Link>
              </div>
              <div className="left">
                <div className="contentWrapper">{children}</div>
              </div>
              <div className="right"></div>
            </PageContainer>
            <Pwa />
          </>
        )
      }}
    </ThemeContext.Consumer>
  )
}

export default AuthLayout
